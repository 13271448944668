import { Row, Col, Container, Nav, Navbar, Spinner, Button } from 'react-bootstrap';
import { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { w3cwebsocket } from 'websocket';
// import { useUser } from '../../../hooks/auth';
import classNames from 'classnames';

// component
import Menu from './Menu';
import NotificationDropdown from './NotificationDropdown';
import ProfileDropdown from './ProfileDropdown';
import {
  businessLogoSimple,
  standardLogo,
  bloghouseLogoSmall
} from '../../../assets/images'

// utilities
import { 
  authContextHelper, 
  types, 
  variables, 
  helpers 
} from 'utilities';
import { isNullOrUndefined } from 'util';

type TopNavProps = {
    isSticky?: boolean;
    navClass?: string;
    fixedWidth?: boolean;
    showTopSearchBar: boolean;
};

type WebSocketSendFunction = (message: any) => void;

const getNotificationProductVariant = (productNewStatus: string): string => {
  let variant = 'primary';

  switch(productNewStatus.toLowerCase()){
  case 'running':
    variant = 'success';
    break;
        
  case 'stopped':
    variant = 'danger';
    break;
        
  default:
    variant = 'orange';
    break;
  }

  return variant;
}

const safeSend = (socket: w3cwebsocket): WebSocketSendFunction => {
  return (message: any) => {
    try {
      // Check if socket is open before sending the message
      if (socket.readyState === socket.OPEN) {
        socket.send(JSON.stringify(message));
      } else if (socket.readyState === socket.CLOSED) {
        // Reconnect the socket and resend the message
        
        socket = new w3cwebsocket(variables.DEFAULT_CONFIG ? variables.DEFAULT_CONFIG['WEB_SOCKET_ENDPOINT']: 'wss://socket.enterprise.kuro.click');
        socket.onopen = () => {
          
          // Resend the message after reconnecting
          socket.send(JSON.stringify(message));
        };
      }
    } catch (error) {
      helpers.logToOutput('Error occurred while sending message:', error);
    }
  };
};

const Topnav: React.FC<TopNavProps> = ({ isSticky, navClass, fixedWidth, showTopSearchBar }: TopNavProps) => {
  const authContext = useContext(authContextHelper.AuthContext);
  const isSignedIn = authContext.authStatus === authContextHelper.AuthStatus.SignedIn;
  const [notificationsSorted, setNotificationsSorted] = useState<any>([]);
  const [isNewNotifications, setIsNewNotifications] = useState<boolean>(true);
  const [userAttributes, setUserAttributes] = useState<types.IAccountUserAttributes | null>({
    ...authContext.userProfile['attributes'],
    role: authContext.userProfile['role']
  });

  //let navSocket:w3cwebsocket = null
  let navSocket = new w3cwebsocket(variables.DEFAULT_CONFIG ? variables.DEFAULT_CONFIG['WEB_SOCKET_ENDPOINT']: 'wss://socket.enterprise.kuro.click');
  const send = safeSend(navSocket);

  const location = useLocation();

  const onPingMessage = {action: variables.DEFAULT_WEB_SOCKET_ACTIONS.PING, message: 'ping'}

  const handleWebsocketProdNotification = (msg:any) => {
    //helpers.logToOutput('Rx PRODUCT_NOTIFICATION On Nav WebSocket : ', msg);
    
    let newNotification = {
      icon: 'message-square',
      variant: getNotificationProductVariant(msg.new.state),
      text: `${msg.new.tags.kname} : ${msg.new.state.toUpperCase()}`,
      //time: `${getTimeAgo(parseInt(socketData["time"]))}`
      time: helpers.getConditionalDateTime(msg.time)
    }
    setNotificationsSorted([
      newNotification,
      ...notificationsSorted
    ]);

    setIsNewNotifications(true);
  }

  const handleWebsocketMessage = (msg:any) => {
    //helpers.logToOutput('Rx message On Nav WebSocket : ', msg);
    const socketData = JSON.parse(msg.data)

    if(socketData){
      if(socketData.action){
        
        switch (socketData.action) {
        case variables.DEFAULT_WEB_SOCKET_ACTIONS.PONG:
          //helpers.logToOutput('Rx ' + socketData.action + ' message On Nav WebSocket : ', socketData.message);
          break;
        case variables.DEFAULT_WEB_SOCKET_ACTIONS.PRODUCT_NOTIFICATION:
          //helpers.logToOutput('Rx ' + socketData.action + ' message On Nav WebSocket : ', socketData.message);
          handleWebsocketProdNotification(socketData.message);
          break;
        default:
          helpers.logToOutput('Rx Unknown message On Nav WebSocket : ', socketData);
        } 
      }
    }
  }

  const handleWebsocketErrorMessage = (msg:any) => {
    helpers.logToOutput('Rx error On Nav WebSocket : ', msg);
  }
  

  const handleSignOut = async (event: React.MouseEvent) => {
    event.preventDefault();
    authContext.signOut();
  }

  // const logWebSocketEvents = (payload: any) => {

  //   // If Reasonable, Add to notifications queue
  //   // Fetch latest notifications
  //   // Set latest notifictaion first
  //   // Then spread rest
  // }

  useEffect(() => {

    //navSocket = new w3cwebsocket(variables.DEFAULT_CONFIG ? variables.DEFAULT_CONFIG['WEB_SOCKET_ENDPOINT']: 'wss://socket.enterprise.kuro.click');

    navSocket.onopen = () => {
      helpers.logToOutput('Opening Nav WebSocket');
    };

    navSocket.onclose = () => {
      helpers.logToOutput('Closing Nav WebSocket');
    };

    navSocket.onmessage = (message: any) => {
      handleWebsocketMessage(message);
    }

    navSocket.onerror = (message: any) => {
      handleWebsocketErrorMessage(message);
    }

    const websocketPing = () => {
      //navSocket.send(JSON.stringify(onPingMessage));
      send(onPingMessage)
    };

    // Set up the interval when the component mounts
    //const intervalIdSocket = setInterval(websocketPing, 20000);

    return () => {
      //clearInterval(intervalIdSocket);
      //navSocket.close // close socket
    }

  }, []); // Run once on initial render

  // on scroll add navbar class
  useEffect(() => {
    const btnTop = document.getElementById('btn-back-to-top');

    window.addEventListener('scroll', (e) => {
      e.preventDefault();
      if (btnTop) {
        if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
          btnTop.classList.add('show');
        } else {
          btnTop.classList.remove('show');
        }
      }
    });

    // Setup Websocket notifications listener
    // webSocket.socket.on("product", logWebSocketEvents);
    // webSocket.socket.on("", logWebSocketEvents);
    // webSocket.socket.on("NOTIFICATION_EVENT", logWebSocketEvents);

    

    attachWebSocket();

    // getUserAttributes();
    setUserAttributes({
      ...authContext.userProfile['attributes'],
      role: authContext.userProfile['role']
    })

        
  }, [authContext.userProfile]);

  
  

  const attachWebSocket = () => {
  }


  const clearNotifications = () => {
    setNotificationsSorted([])
  }


  const triggerWebSocketPayload = async (event: any) => {
    await fetch('/api/socket');  

  }


  return (
    <>
      {location.pathname === '/product-window'? 
        null
        :
        <div id="k-app-header" className="k-header-height">
          <header>
            <Navbar
              id={isSticky ? 'sticky' : ''}
              collapseOnSelect
              expand="lg"
              className={classNames('topnav-menu navbar-sticky', navClass)}
            >
              <Container fluid={!fixedWidth}>
                {/*
                      <Navbar.Brand href="/" className="logo">
                          <img src={logo} height="30" className="align-top logo-dark" alt="" />
                          <img src={logoLight} height="30" className="align-top logo-light" alt="" />
                      </Navbar.Brand>
                      */}

                <Navbar.Toggle className="me-3" aria-controls="topnav-menu-content4" />

                <Navbar.Collapse id="topnav-menu-content4">
                  <Menu />
                          
                  <Nav as="ul" className="navbar-sections align-items-lg-center">
                    <div className='pt-2 ps-2'> {/* This is the left section */}
                      {/*<img className='k-mh-30' src={businessLogo}></img>*/}
                      {
                        showTopSearchBar ? (
                          location.pathname !== '/' ? (
                            <Link to="/">
                              {variables.DEFAULT_CONFIG ? (
                                variables.DEFAULT_CONFIG['BRANDING'] === 'STANDARD' ? (
                                  <img className="ms-2 k-mh-40" src={standardLogo} alt="Standard Logo"></img>
                                ) : variables.DEFAULT_CONFIG['BRANDING'] === 'BLOGHOUSE' ? (
                                  <img className="ms-2 k-mh-30" src={bloghouseLogoSmall} alt="Bloghouse Logo"></img>
                                ) : (
                                  <img className="ms-2 k-mh-40" src={businessLogoSimple} alt="Business Logo"></img>
                                )
                              ) : null}
                            </Link>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )
                      }
                    </div> 
                    <div className="nav-item"> {/* This is the middle section */}
                              
                                  
                    </div>
                    { isSignedIn ?   
                      <div className="align-items-lg-center"> {/* This is the right section */}
                        <Row className='m-0 p-0'> 
                          <Col className='m-1 p-0'>
                            {/*<Button onClick={triggerWebSocketPayload}>Sock-Trig</Button>*/}
                          </Col>
                      
                          <Col className='m-0 p-0'>
                            <NotificationDropdown 
                              notifications={notificationsSorted} 
                              isNewNotifications={isNewNotifications}
                              setIsNewNotifications={setIsNewNotifications}
                              clearNotifications={clearNotifications}
                            />
                          </Col>
                          <Col className='m-0 p-0'>
                            {userAttributes &&
                          <ProfileDropdown 
                            userAttributes={userAttributes} 
                            handleSignOut={handleSignOut}
                          />}
                          </Col>

                        </Row>
                      </div>
                      :
                      <></>
                    }
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </header>
        </div>
      }
    </>
    
  );
};

export default Topnav;
