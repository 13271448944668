import { useEffect } from 'react';
import AOS from 'aos';
import { ToastContainer } from 'react-toastify';

// routes
import Routes from './routes';
import { variables } from './utilities';

// Services
// import { webSocket } from './utilities/services';

// Themes
//  For Default import Theme.scss
import './assets/scss/theme.scss';
import 'react-toastify/dist/ReactToastify.css';



const App: React.FC = () => {
  useEffect(() => {
    AOS.init();

    // Connect Websocket
    // webSocket.attachWebSocket();

    // return applicationCleanup()

    if (variables.DEFAULT_CONFIG) {
      const favicon = document.getElementById('favicon') as HTMLLinkElement;

      if (favicon) {
        // Check if BRANDING is 'STANDARD'
        if ( variables.DEFAULT_CONFIG['BRANDING'] === 'STANDARD') {
          favicon.href = process.env.PUBLIC_URL + '/favicon-standard.png'
        } else if (variables.DEFAULT_CONFIG['BRANDING'] === 'BLOGHOUSE') {
          favicon.href = process.env.PUBLIC_URL + ''
        } else {
          favicon.href = process.env.PUBLIC_URL + '/favicon-other.png';
        }
      }
      if ( variables.DEFAULT_CONFIG['BRANDING'] === 'STANDARD') {
        document.title = 'Intelligent Cloud'
      } else if (variables.DEFAULT_CONFIG['BRANDING'] === 'BLOGHOUSE') {
        document.title = 'BlogHouse'
      } else {
        document.title = 'Kuro'
      }
    }


  }, []);

    
  // const applicationCleanup = () => {
  //   // webSocket.detachWebSocket();
  // }
  return (
    <>
      <ToastContainer
        style={{ zIndex: '1000000' }} // Above current nav z-index 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={'light'}
      />
      <Routes />
    </>
  );
};

export default App;
