import { useDroppable } from '@dnd-kit/core';
import { FC, useEffect, useState } from 'react';

import { helpers, types, variables, api } from 'utilities'


const handleIframeMouseOver = async(event: any) => {
  event.preventDefault();
  const iframeDocument = document.getElementById(event.target.id);
  iframeDocument?.focus();
}

const openNewWindow = async(productID: any, screenSize: any) => {

  const [width, height] = screenSize.split('x');
  const newHeight = (parseInt(height) + 25).toString(); // need toadd 25px for the banner

  

  let windowName = 'Product Window';
  let windowTitle = 'Kuro';

  // Remove and cleanup current window vp
  // handleNewWindowOpenCleanUp(event)

  //const windowFeatures = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, width=${width} height=${newHeight}`;
  const windowFeatures = `width=${width} height=${newHeight}`;

  // Open a new window with the specified URL, unique name, and features
  const pu = `${variables.DEFAULT_CONFIG['WEB_APP_URL']}/product-window?productId=${productID}`;

  const newWindow = window.open(pu, windowName, windowFeatures);

  if (newWindow) {
    // Change the title of the popup window
    newWindow.document.title = windowTitle;

    // Optionally, pass data to the new window using the window.opener property
    newWindow.opener.postMessage(productID, '*');

    //TO-DO: Remove from current vp
  } else {
    // Handle the case where the window couldn't be opened
    alert('The window could not be opened. Please check your popup blocker settings.');
  }

}

const DynamicWindow: FC<types.IViewport> = ({ vpProductIndex, isRunning, products, viewportProducts }) => {
  const [screenSize, setScreenSize] = useState<string>('');
  
  const { setNodeRef } = useDroppable({
    id: `cartdroppable-${vpProductIndex}`
  });
  
  useEffect(() => {
    const fetchScreenSize = async () => {
      if (viewportProducts[vpProductIndex]?.id) {
        const screenSizeRes = await helpers.getProductMeta('screendimension', viewportProducts[vpProductIndex]?.id);
        
        if (screenSizeRes.status === 'ok') {
          setScreenSize(screenSizeRes.response);
        }
      }
    };
  
    fetchScreenSize();
  }, [viewportProducts[vpProductIndex]?.id]);


  
  
  
  
  return (
    <div id={`cartdroppable-${vpProductIndex}`} className={'cart k-iFrameHolder k-center-content'} ref={setNodeRef}>
      { !screenSize ? (
        <>
          {isRunning ? 
            <iframe className="k-iFrame d-flex align-self-stretch"
              allow="clipboard-read; clipboard-write;camera; microphone; web-share; display-capture;" id={`desktop-iframe-vp-${vpProductIndex}`} 
              src={viewportProducts[vpProductIndex]?.url} title="window"
              onMouseOver={handleIframeMouseOver}>
            </iframe>
            : 
            <>
              <span></span>
            </>
          }
        </>
      ) : 
        (
          <>
            {isRunning ? 
              <span>
                <p className='pt-4 k-center-content'>A specific screen size has been set on this device</p>
                <p className='k-pointer color-RS k-center-content' onClick={() => openNewWindow(viewportProducts[vpProductIndex]?.id, screenSize)}>
                  Please click here to open a new window
                </p>
              </span>
              : 
              <>
                <span></span>
              </>
            }
          </>
        )
        
      }
    </div>
  );
};

const FullWindow: FC<types.IViewportFullWindow> = ({ product, isRunning, productUrl }) => {

  
  

  useEffect(() => {
    const setDisplay = () => {
      if (product.screendimension) {
        const [width, height] = product.screendimension.split('x');
        const fullWindow = document.getElementById('cartdroppable-fullwindow');
        const windowHeight = (parseInt(height) + 82);
        if (fullWindow) {
          fullWindow.style.width = `${width}px`;
          fullWindow.style.height = `${height}px`;
          
          window.resizeTo(parseInt(width), windowHeight);
        }
      }
    };
  
    setDisplay();
  }, [product.screendimension]);

  return (
    <div id={'cartdroppable-fullwindow'} className={'cart k-iFrameHolder'}>
      {isRunning ? 
        <iframe className="k-iFrame d-flex align-self-stretch" 
          allow="clipboard-read; clipboard-write;camera; microphone; web-share; display-capture;" id={'desktop-iframe-vp-1'} 
          src={productUrl} title="BOB"
          onMouseOver={handleIframeMouseOver}>
        </iframe>
        :
        <p>Product Is Not Running, Try a refresh</p>
      }
    </div>
  );
};

const fetchActiveConnection = async(url: string, method: string, contentType: string, token: any): Promise<string> => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': contentType,
        'Guacamole-Token': token
      }
    });

    if (!response.ok) {
      throw new Error('Flash Network response was not ok');
    }

    const data = await response.text(); // Change to response.json() if expecting JSON data
    
    return data;
  } catch (error) {
    
    throw error; // Rethrow the error to be caught by the caller
  }
}

const removeConnection = async (url: string, method: string, contentType: string, token: any, path: string): Promise<string> => {
  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': contentType,
        'Guacamole-Token': token
      },
      body: JSON.stringify([{
        'op': 'remove',
        'path': path
      }]),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.text(); // Change to response.json() if expecting JSON data
    
    return data;
  } catch (error) {
    
    throw error; // Rethrow the error to be caught by the caller
  }
}

export {
  DynamicWindow, 
  FullWindow
};